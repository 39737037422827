import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import ImageUploading from "react-images-uploading";
import QuizIcon from "@mui/icons-material/Quiz";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import {
  formError,
  formSetValue,
} from "../../../modules/hcmd/components/formUtils";
import { getPictureSrc, importUsers } from "../../../services/user";
import { HorizontalRule } from "@mui/icons-material";
import { Alert, AlertTitle } from "@mui/lab";

function ImportUser(props) {
  const [site, setSite] = useState("md");
  const [fields, setFields] = useState([]);
  const [schema, setSchema] = useState({});

  const [excelFile, setExcelFile] = useState("");
  const [excels, setExcels] = React.useState([]);
  const maxNumber = 1;

  const [responseColor, setResponseColor] = useState("error");
  const [responseTitle, setResponseTitle] = useState("");
  const [responseList, setResponseList] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const abc = "-ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (site === "md") {
      setFields([
        { attr: "lastName", label: "vezetéknév", helperText: " " },
        { attr: "firstName", label: "keresztnév", helperText: " " },
        { attr: "email", label: "email", helperText: " " },
        { attr: "sex", label: "nem", helperText: "elfogadott: férfi / nő" },
        {
          attr: "nationalityId",
          label: "állampolgárság",
          helperText: "pl.: magyar, angol, német",
        },
        {
          attr: "nativeLanguageId",
          label: "anyanyelv",
          helperText: "pl.: magyar, angol, német",
        },
        {
          attr: "birthDate",
          label: "születési dátum",
          helperText: "formátum: ÉÉÉÉ-HH-NN",
        },
        {
          attr: "positionId",
          label: "pozíció",
          helperText: "azonosítók vesszővel elválasztva",
        },
      ]);
    } else if (site === "hc") {
      setFields([
        { attr: "lastName", label: "vezetéknév", helperText: " " },
        { attr: "firstName", label: "keresztnév", helperText: " " },
        { attr: "email", label: "email", helperText: " " },
        { attr: "sex", label: "nem", helperText: "elfogadott: férfi / nő" },
        {
          attr: "nationalityId",
          label: "állampolgárság",
          helperText: "pl.: magyar, angol, német",
        },
        {
          attr: "nativeLanguageId",
          label: "anyanyelv",
          helperText: "pl.: magyar, angol, német",
        },
        {
          attr: "birthDate",
          label: "születési dátum",
          helperText: "formátum: ÉÉÉÉ-HH-NN",
        },
        {
          attr: "positionId",
          label: "pozíció",
          helperText: "azonosítók vesszővel elválasztva",
        },
      ]);
    }
  }, [site]);

  useEffect(() => {
    if (fields.length) {
      const storedSchema = window.localStorage.getItem(
        `UserImportExcelSchema-${site}`
      );
      if (!storedSchema || !storedSchema.match(/^\{.*\}$/)) {
        const newSchema = {};
        fields.map((field, index) => {
          if (field.attr === "positionId") {
            newSchema[field.label] = abc[0];
          } else {
            newSchema[field.label] = abc[index + 1];
          }
        });
        setSchema(newSchema);
      } else if (storedSchema && storedSchema.match(/^\{.*\}$/)) {
        setSchema(JSON.parse(storedSchema));
      }
    }
  }, [site, fields]);

  useEffect(() => {
    if (Object.keys(schema).length > 0) {
      window.localStorage.setItem(
        `UserImportExcelSchema-${site}`,
        JSON.stringify(schema)
      );
    }
    return () => {};
  }, [schema]);

  const handleChange = (event) => {
    setSite(event.target.value);
  };

  // console.log("DocumentInput initialValue", initialValue);

  useEffect(() => {
    if (typeof excelFile === "object" && excelFile?.data) {
      console.log("excel object", excelFile);
      // formSetValue(formik, column, JSON.stringify(excelFile));
    } else {
      // if (excelFile && values.id) {
      //   setExcels([
      //     { dataUrl: getPictureSrc(excelFile, values.id), filename: excelFile },
      //   ]);
      // } else if (excelFile) {
      //   setExcels([{ dataUrl: getPictureSrc(excelFile) }]);
      // }
      console.log("excel data", excelFile);
      // formSetValue(formik, column, excelFile);
    }
  }, [excelFile]);

  const handleExcelChange = (imageList, addUpdateIndex) => {
    // data for submit
    setExcels(imageList);
    setExcelFile({
      data: imageList[0].dataUrl,
      meta: {
        filename: imageList[0].file.name,
        filesize: imageList[0].file.size,
      },
    });
  };

  const handleSubmit = () => {
    setOpen(true);
  };

  const handleAgree = () => {
    setOpen(false);
    importUsers(excelFile, schema, site)
      .then((response) => {
        setExcelFile("");
        setExcels([]);

        if (response.data.success) {
          // sikeres
          setResponseColor("success");
          setResponseTitle(response.data.message);
          setResponseList([]);
        } else {
          // hibak
          setResponseColor("error");
          setResponseTitle(response.data.message);
          setResponseList(response.data.errors);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid lg={12}>
          <Grid
            container
            justifyContent={"center"}
            direction={"column"}
            spacing={3}
            sx={{ width: "100%" }}
          >
            <Grid lg={6} sx={{ textAlign: "center" }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Oldal
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="site-choose"
                  name="site"
                  value={site}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="md"
                    control={<Radio color={"error"} />}
                    label="Mind-Diák"
                  />
                  <FormControlLabel
                    value="hc"
                    control={<Radio color={"info"} />}
                    label="HumánCentrum"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid lg={8} xl={6}>
          <Typography
            variant={"h6"}
            color={"error"}
            sx={{ textAlign: "right", pr: 6, mb: 3 }}
          >
            Az excel 1. sorában ezek a fejléc értékek szerepeljenek:
          </Typography>
          <Typography
            variant={"subtitle1"}
            color={"info"}
            sx={{ textAlign: "right", pr: 6, mb: 3 }}
          >
            Minta excel:{" "}
            <a
              href={`/excel/import-${site}-minta.xlsx`}
              target={"_blank"}
            >{`/excel/import-${site}-minta.xlsx`}</a>
          </Typography>
          <Grid
            container
            direction={"column"}
            spacing={3}
            sx={{ width: "100%" }}
          >
            <Grid sx={{ width: "100%" }}>
              {fields.map((field, index) => (
                <Box key={index} sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={3}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                  >
                    <Grid lg={6} sx={{ textAlign: "right" }}>
                      {field.label}
                    </Grid>
                    <Grid lg={6}>
                      <TextField
                        id={field.attr}
                        select
                        variant="outlined"
                        value={
                          schema[field.label]
                            ? schema[field.label]
                            : field.attr === "positionId"
                            ? abc[0]
                            : abc[index + 1]
                        }
                        onChange={(event) =>
                          setSchema({
                            ...schema,
                            [field.label]: event.target.value,
                          })
                        }
                        sx={{ width: "100%" }}
                        helperText={field.helperText}
                      >
                        {abc.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid lg={4} xl={6}>
          <FormControl sx={{ width: "100%", mb: 3 }}>
            <ImageUploading
              multiple={false}
              value={excels}
              onChange={handleExcelChange}
              maxNumber={maxNumber}
              dataURLKey="dataUrl"
              allowNonImageType={true}
              maxFileSize={5 * 1024 * 1024}
              acceptType={["xls", "xlsx", "ods"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
                errors,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  {!imageList.length && (
                    <Button
                      variant={"contained"}
                      color={isDragging ? "secondary" : "primary"}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Excel feltöltése
                      <br />
                      (kattints vagy húzd rá)
                    </Button>
                  )}
                  &nbsp;
                  {/*<button onClick={onImageRemoveAll}>Remove all images</button>*/}
                  {imageList.map((item, index) => {
                    console.log("imageList item", item);
                    const itemFilename =
                      typeof item.filename === "string" &&
                      item.filename.match(/^\{.*\}$/)
                        ? JSON.parse(item.filename)
                        : item.filename;
                    const filename =
                      item.file?.name ??
                      itemFilename?.meta?.filename ??
                      itemFilename ??
                      "";
                    let icon = <DescriptionIcon />;
                    return (
                      <div key={index} className="image-item">
                        <Button variant="contained" startIcon={icon}>
                          {filename}
                        </Button>
                        <div className="image-item__btn-wrapper">
                          <IconButton
                            onClick={() => onImageUpdate(index)}
                            sx={{ mb: 2 }}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })}
                  {errors && (
                    <div>
                      {errors.maxNumber && (
                        <span>Number of selected images exceed maxNumber</span>
                      )}
                      {errors.acceptType && (
                        <span>Your selected file type is not allow</span>
                      )}
                      {errors.maxFileSize && (
                        <span>Selected file size exceed maxFileSize</span>
                      )}
                      {errors.resolution && (
                        <span>
                          Selected file is not match your desired resolution
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </ImageUploading>
          </FormControl>
          <Button
            variant={"contained"}
            color={"error"}
            onClick={handleSubmit}
            disabled={!excelFile}
          >
            Importálás
          </Button>
          <Divider sx={{ my: 3, width: "100%" }}></Divider>
          {responseTitle && (
            <Alert severity={responseColor}>
              <AlertTitle>{responseTitle}</AlertTitle>
              {responseList.map((msg, index) => (
                <>
                  <Typography variant={"subtitle"} key={index}>
                    {msg}
                  </Typography>
                  <br />
                </>
              ))}
            </Alert>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Importálás"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A kitallózott Excel importálást biztosan a{" "}
            <b>{site === "md" ? "Mind-Diák Szövetkezet" : "HumánCentrum"}</b>{" "}
            adatbázisba szeretnéd indítani?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Mégsem
          </Button>
          <Button onClick={handleAgree}>Igen, biztosan</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ImportUser.propTypes = {};

export default ImportUser;
